import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  // Home
  { path: '', loadChildren: () => import('./components/pages/home/home.module').then(m => m.HomeModule), data: { breadcrumb: "Homepage" } },
  { path: 'home-v2', loadChildren: () => import('./components/pages/home-two/home-two.module').then(m => m.HomeTwoModule), data: { breadcrumb: "Homepage" } },
  { path: 'home-v3', loadChildren: () => import('./components/pages/home-three/home-three.module').then(m => m.HomeThreeModule), data: { breadcrumb: "Homepage" } },
  // Blog
  { path: 'blog/cat/:catId', loadChildren: () => import('./components/pages/blog/blog.module').then(m => m.BlogModule), data: { breadcrumb: "Blog" } },
  { path: 'blog/tag/:tagId', loadChildren: () => import('./components/pages/blog/blog.module').then(m => m.BlogModule), data: { breadcrumb: "Blog" } },
  { path: 'blog/author/:authorId', loadChildren: () => import('./components/pages/blog/blog.module').then(m => m.BlogModule), data: { breadcrumb: "Blog" } },
  { path: 'blog/search/:query', loadChildren: () => import('./components/pages/blog/blog.module').then(m => m.BlogModule), data: { breadcrumb: "Blog" } },
  { path: 'blog', loadChildren: () => import('./components/pages/blog/blog.module').then(m => m.BlogModule), data: { breadcrumb: "Blog" } },
  { path: 'blog-list', loadChildren: () => import('./components/pages/blog-list/blog-list.module').then(m => m.BlogListModule), data: { breadcrumb: "Blog List" } },
  { path: 'blog-masonry', loadChildren: () => import('./components/pages/blog-masonry/blog-masonry.module').then(m => m.BlogMasonryModule), data: { breadcrumb: "Blog Masonry" } },
  { path: 'blog-details/:id', loadChildren: () => import('./components/pages/blog-details/blog-details.module').then(m => m.BlogDetailsModule), data: { breadcrumb: "Blog Details" } },
  // Pages
  { path: 'about', loadChildren: () => import('./components/pages/about/about.module').then(m => m.AboutModule), data: { breadcrumb: "About Us" } },
  { path: 'pastor-details/:id', loadChildren: () => import('./components/pages/pastor-details/pastor-details.module').then(m => m.PastorDetailsModule), data: { breadcrumb: "Pastor Details" } },
  { path: 'history', loadChildren: () => import('./components/pages/history/history.module').then(m => m.HistoryModule), data: { breadcrumb: "History" } },
  { path: 'prayer-wall', loadChildren: () => import('./components/pages/prayer-wall/prayer-wall.module').then(m => m.PrayerWallModule), data: { breadcrumb: "Prayer Wall" } },
  { path: 'contact', loadChildren: () => import('./components/pages/contact/contact.module').then(m => m.ContactModule), data: { breadcrumb: "Contact Us" } },
  // Event
  { path: 'event', loadChildren: () => import('./components/pages/event/event.module').then(m => m.EventModule), data: { breadcrumb: "Event" } },
  { path: 'event-details/:id', loadChildren: () => import('./components/pages/event-details/event-details.module').then(m => m.EventDetailsModule), data: { breadcrumb: "Event Details" } },
  { path: 'event-extended/:id', loadChildren: () => import('./components/pages/event-extended/event-extended.module').then(m => m.EventExtendedModule), data: { breadcrumb: "Event Extended" } },
  // Sermon
  { path: 'sermon/tag/:tagId', loadChildren: () => import('./components/pages/sermon/sermon.module').then(m => m.SermonModule), data: { breadcrumb: "Sermon" } },
  { path: 'sermon/author/:authorId', loadChildren: () => import('./components/pages/sermon/sermon.module').then(m => m.SermonModule), data: { breadcrumb: "Sermon" } },
  { path: 'sermon', loadChildren: () => import('./components/pages/sermon/sermon.module').then(m => m.SermonModule), data: { breadcrumb: "Sermon" } },
  { path: 'sermon-details/:id', loadChildren: () => import('./components/pages/sermon-details/sermon-details.module').then(m => m.SermonDetailsModule), data: { breadcrumb: "Sermon Details" } },
  // Donation
  { path: 'donation', loadChildren: () => import('./components/pages/donation/donation.module').then(m => m.DonationModule), data: { breadcrumb: "Donation" } },
  { path: 'donation-details/:id', loadChildren: () => import('./components/pages/donation-details/donation-details.module').then(m => m.DonationDetailsModule), data: { breadcrumb: "Donation Details" } },
  // Shop
  { path: 'shop/cat/:catId', loadChildren: () => import('./components/pages/shop/shop.module').then(m => m.ShopModule), data: { breadcrumb: "Shop" } },
  { path: 'shop/tag/:tagId', loadChildren: () => import('./components/pages/shop/shop.module').then(m => m.ShopModule), data: { breadcrumb: "Shop" } },
  { path: 'shop/search/:query', loadChildren: () => import('./components/pages/shop/shop.module').then(m => m.ShopModule), data: { breadcrumb: "Shop" } },
  { path: 'shop/:minPrice/:maxPrice', loadChildren: () => import('./components/pages/shop/shop.module').then(m => m.ShopModule), data: { breadcrumb: "Shop" } },
  { path: 'shop', loadChildren: () => import('./components/pages/shop/shop.module').then(m => m.ShopModule), data: { breadcrumb: "Shop" } },
  { path: 'shop-details/:id', loadChildren: () => import('./components/pages/shop-details/shop-details.module').then(m => m.ShopDetailsModule), data: { breadcrumb: "Shop Details" } },
  { path: 'wishlist', loadChildren: () => import('./components/pages/wishlist/wishlist.module').then(m => m.WishlistModule), data: { breadcrumb: "Wishlist" } },
  { path: 'cart', loadChildren: () => import('./components/pages/cart/cart.module').then(m => m.CartModule), data: { breadcrumb: "Cart" } },
  { path: 'checkout', loadChildren: () => import('./components/pages/checkout/checkout.module').then(m => m.CheckoutModule), data: { breadcrumb: "Checkout" } }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
